<template>
  <div>
    <ts-page-title :title="$t('loanActiveByCo.pageTitle')" :breadcrumb="[
      { text: $t('home'), href: '/' },
      {
        text: $t('loanActiveByCo.pageTitle'),
        active: true,
      },
    ]" />
    <ts-panel>
      <ts-panel-wrapper>
        <div class="row">
          <div class="col-lg-3">
            <div class="card border-0 bg-gray-900">
              <div class="card-body">
                <div class="text-gray-500">
                  <b>{{ $t("loanActiveByCo.filterCriteria") }}</b>
                </div>
              </div>
              <div class="widget-list rounded-bottom">
                <div class="mb-3 mt-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                      $t("summaryOfActiveAccount.classifiedBy")
                    }}</label><br />
                    <RadioGroup v-model="model.classified_by" @on-change="model.employee_id = []" size="small">
                      <Radio class="mt-1" :label="c.value" border v-for="(c, index) in classified" :key="index">
                        {{ c.label }}
                      </Radio>
                    </RadioGroup>
                    <div class="text-danger" v-if="errors.has('classified_by')">
                      {{ errors.first("classified_by") }}
                    </div>
                  </div>
                </div>

                <div class="mb-3 mt-3">
                  <div class="col-md-12">
                    <Checkbox v-model="model.new_client">New Client
                    </Checkbox>
                  </div>
                </div>

                <div class="mb-3 mt-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                      $t("loanActiveByCo.branch")
                    }}</label>
                    <ts-branch-filter @filter="(value) => (model.branch_id = value)" :isPlaceholder="true" />
                    <div class="text-danger" v-if="errors.has('branch_id')">
                      {{ errors.first("branch_id") }}
                    </div>
                  </div>
                </div>
                <div class="mb-3" v-if="model.classified_by == 'co'">
                  <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                      $t("loanActiveByCo.co")
                    }}</label>
                    <Select v-model="model.employee_id" multiple :max-tag-count="1" :filterable="true"
                      :filter-by-label="true" :placeholder="$t('all')">
                      <Option v-for="item in employees" :value="item.employee_id" :key="item.employee_id"
                        :label="item.employee_name_en">
                        {{ item.employee_name_en }}
                      </Option>
                    </Select>
                    <div class="invalid-feedback" v-if="errors.has('employee_id')">
                      {{ errors.first("employee_id") }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                      $t("loanActiveByCo.effectiveDate")
                    }}</label>
                    <DatePicker v-model="model.effective_date" placement="bottom-end" style="width: 100%"
                      :transfer="true" format="dd-MM-yyyy" @on-change="onChangeTransactionDate"></DatePicker>
                    <div class="text-danger" v-if="errors.has('effective_date')">
                      {{ errors.first("effective_date") }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                      $t("loanActiveByCo.currency")
                    }}</label><br />
                    <RadioGroup v-model="model.currency_id" size="small">
                      <Radio class="mt-1" :label="c.currency_id" border v-for="(c, index) in currencies" :key="index">
                        {{ c.currency_code }}
                      </Radio>
                    </RadioGroup>
                    <div class="text-danger" v-if="errors.has('currency_id')">
                      {{ errors.first("currency_id") }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                      $t( 'disbursementList.excelTemplate')
                    }}</label><br />
                    <RadioGroup size="small" v-model="model.excel_type">
                      <Radio class="mt-1" :label="c.value" border v-for="(c, index) in excel" :key="index">
                        {{ c.label }}
                      </Radio>
                    </RadioGroup>
                  </div>
                </div>
                <div class="mb-3 tw-justify-end tw-flex tw-space-x-2">
                  <ts-button outline color="success" @click.prevent="exportExcel" :waiting="exporting">
                    <i class="far fa-file-excel" v-if="!exporting"></i>
                    {{ $t("exportExcel") }}</ts-button>
                  <ts-button color="danger" outline @click.prevent="preview" :waiting="waiting">
                    <i class="far fa-file-pdf" v-if="!waiting"></i>
                    {{ $t("previewPdf") }}</ts-button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div id="container">
              <ts-preview-pdf v-model="waiting" :src="src" />
            </div>
          </div>
        </div>
      </ts-panel-wrapper>
    </ts-panel>
  </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
import * as FileDownload from "downloadjs";
import { trim } from "lodash";

export default {
  name: "loanActiveByCo",
  data() {
    return {
      waiting: false,
      exporting: false,
      errors: new Errors(),
      src: "",
      model: {
        classified_by: "co",
        excel_type: 'standard',
        new_client: false,
        branch_id: [],
        employee_id: [],
        currency_id: null,
        effective_date: moment().format("DD-MM-YYYY"),
      },
      excel: [
        {
          value: 'standard',
          label: 'Standard'
        },
        {
          value: 'raw_data',
          label: 'Raw Data'
        }
      ],
      classified: [
        {
          value: "co",
          label: "Credit Officer",
        },
        {
          value: "branch",
          label: "Branch",
        },
      ],
    };
  },
  created() {
    this.model.currency_id =
      this.$store.state.authUser.sys_setting[0].default_currency_id;
  },
  computed: {
    ...mapState("report/resource", ["employees", "currencies"]),
  },
  methods: {
    ...mapActions("report/resource", ["getEmployee", "getCurrency"]),
    preview() {
      this.errors = new Errors();
      this.waiting = true;
      this.src = "";
      this.$store
        .dispatch("report/creditOperation/loanActiveByCo", this.model)
        .then((response) => {
          this.src = response.url;
        })
        .catch((error) => {
          this.notice({ type: "error", text: error.message });
          this.errors = new Errors(error.errors);
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    exportExcel() {
      this.errors = new Errors();
      this.exporting = true;

      if (this.model.excel_type == 'standard') {
        this.$store
          .dispatch(
            "report/creditOperation/loanActiveByCoExportExcel",
            this.model
          )
          .then(response => {
            let filename = trim(
              response.headers['content-disposition']
                .substring(
                  response.headers['content-disposition'].indexOf(
                    'filename'
                  )
                )
                .replace('filename=', ''),
              '"'
            )
            FileDownload(response.data, filename)
          })
          .catch(err => {
            let error = JSON.parse(
              String.fromCharCode.apply(null, new Uint8Array(err))
            )
            this.notice({ type: 'error', text: error.message })
            this.errors = new Errors(error.errors)
          })
          .finally(() => (this.exporting = false))
      }

      if (this.model.excel_type == 'raw_data') {
        this.$store
          .dispatch(
            "report/creditOperation/loanActiveByCoRawDataCSV",
            this.model
          )
          .then(response => {
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(response.data);
            anchor.target = '_blank';
            anchor.download = 'report-loan-active-by-co.csv';
            anchor.click();
          })
          .catch(err => {
            let error = JSON.parse(
              String.fromCharCode.apply(null, new Uint8Array(err))
            )
            this.notice({ type: 'error', text: error.message })
            this.errors = new Errors(error.errors)
          })
          .finally(() => (this.exporting = false))
      }
    },
    onChangeTransactionDate(date) {
      this.model.effective_date = date;
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "LOAN OVERDUE",
        desc: not.text,
      });
    },
  },
  watch: {
    "model.branch_id": debounce(function (value) {
      this.model.employee_id = [];
      this.getEmployee({
        branch_id: value,
      });
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getCurrency();
    });
  },
};
</script>
